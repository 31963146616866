import React, { Fragment } from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image';
import SEO from '../components/seo'
import Layout from '../components/Layout'
import { rhythm } from '../utils/typography'
import Categories from '../components/Categories/index'
import Pages from '../components/Pages/index'

class CategoriesList extends React.Component {
  render() {
    const { data } = this.props
    // const siteTitle = data.site.siteMetadata.title
    // const posts = data.allMdx.edges
    // const { currentPage, numPages, categories, activeCategory } = this.props.pageContext
    const { currentPage, numPages, activeCategory, allCategories } = this.props.pageContext

    const posts = data.allMarkdownRemark.edges.filter((edge) =>
      edge.node.frontmatter.categories.indexOf(activeCategory) >=0 
    );
    
    
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()


    return (
      <Layout >
        <SEO
          title={activeCategory}
          keywords={[`blog`, `visualization`, `javascript`, `react`]}
        />
        <div className="categories-list">
          <div className="categories-container">
            <div className="columns">
              <h1>{activeCategory}</h1>
            </div>
          </div>
          <div className="recent-posts">
            {posts.map(({ node }) => {
              const categories = node.frontmatter.categories
              const banner = node.frontmatter.banner
              const title = node.frontmatter.title || node.fields.slug
              return (
                <div key={node.fields.slug} className="archive-post">
                  <div className="img-right">
                    {banner && (
                      <Img
                        sizes={banner.childImageSharp.sizes}
                      />
                    )}
                  </div>
                  <h3
                    style={{
                      marginBottom: rhythm(1 / 4),
                    }}
                  >
                    <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
                      {title}
                    </Link>
                  </h3>
                  <small>{node.frontmatter.date}</small>
                  
                  {categories.map(( category ) => {
                    return (
                      <span key={category}>
                        {' | '}
                        <small>
                              <Link to={`/categories/${category}`}>
                                  {category}
                              </Link>
                        </small>
                      </span>
                    )
                  })}
                  <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                  <div className="clr"></div>
                </div>
              )
            })}
            {/* 页面底部分页模块 */}
            <Pages {...{currentPage,numPages,prevPage,nextPage,isFirst,isLast}}/>
          </div>
          <div className="sidebar">
            <Categories categories={allCategories} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default CategoriesList

export const pageQuery = graphql`
  query categoriesPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip) {
      edges {
        node {
          excerpt(format: HTML)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MM月DD日, YYYY")
            title
            categories
            banner {
              childImageSharp {
                sizes(maxWidth: 600) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`
